import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
//import App from './App';
import Blog from './Blog';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import WelcomePage from "./components/WelcomePage";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path={"/"} element={<WelcomePage />} />
                <Route path={"/blog"} element={<Blog />} />
            </Routes>
        </Router>

    </React.StrictMode>
)

reportWebVitals(sendToVercelAnalytics);
