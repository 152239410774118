import React from 'react';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import {Image} from "@mui/icons-material";
import sqImg from '../images/sq-500x500.jpg';
import {Avatar} from "@mui/material";
import Typed from "typed.js";
import {useEffect, useRef} from 'react';
import { Link } from "react-router-dom";
export default function WelcomePage() {
    const el = useRef(null);
    const typed = useRef(null);

    useEffect(() => {
        const options = {
            strings: [
                'Ozan Şan',
                'Human @ Planet Earth',
                'Android Software Engineer @ Atlassian'
            ],
            typeSpeed: 75,
            backSpeed: 25,
            loop: true
        };
        typed.current = new Typed(el.current, options);
        return () => {
            // Make sure to destroy Typed instance during cleanup
            // to prevent memory leaks
            typed.current.destroy();
        };
    }, []);


    return (<div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "black"
    }}>
        <Container fluid className={"mx-auto"}>
            <Row>
                <Col>
                    <Avatar
                        alt="Ozan San"
                        src={sqImg}
                        sx={{width: 200, height: 200}}
                        className={"mx-auto"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{height: "50px"}}></div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"text-center"}>
                        <h1 className={"font-oswald"} style={{
                            color: "whitesmoke"
                        }}>Hi! I'm Ozan.</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"mx-auto text-center"} >
                        <span className={"font-oswald"} style={{
                            color: "white",
                            fontSize: "xx-large"
                        }} ref={el}></span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"mx-auto text-center"}>
                        <span className={"font-oswald"} style={{color: "white", fontSize: "xx-large"}}>
                            Here for my <Link to={"/blog"}>blog</Link>?
                        </span>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>);
}